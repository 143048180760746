import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useState } from "react";

const machineType = machineTypes.Fusion;
const apiUrl = "/fusionapi/api/v1/machines";

function createError(machineId, error) {
	let uiError = {
		machineId,
		machineType,
		severity: errorSeverity.error,
		code: error.message,
	};
	if (error.message === "CorrugateMismatch")
		uiError = {
			...uiError,
			instructionValues: { track: error.arguments[0], corrugate: error.arguments[1] },
		};
	else if (error.message === "OutOfCorrugate")
		uiError = {
			...uiError,
			instructionValues: { track: error.arguments[0] },
		};

	return uiError;
}

export default function FusionErrorsAndWarnings({ machines, onErrorsChanged }) {
	const token = readLocalStorage("BEARER");
	const [machineIds, setMachineIds] = useState([]);
	const [staleData, setStaleData] = useState(false);

	useEffect(() => {
		setMachineIds(
			machines.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase()).map((x) => x.machineId),
		);
	}, [machines]);

	useEffect(() => {
		if (!staleData) {
			const handle = setTimeout(() => setStaleData(true), 5000);
			return () => clearTimeout(handle);
		}

		async function getErrorsAndWarnings(abortController) {
			for (const machineId of machineIds) {
				try {
					const response = await fetch(`${apiUrl}/${machineId}`, {
						headers: { Authorization: token },
						signal: abortController.signal,
					});

					if (!response.ok) {
						throw new Error("Did not successfully complete request");
					}

					if (!abortController.signal.aborted && response.status === 204) {
						onErrorsChanged(machineId, []);
						continue;
					}

					const data = await response.json();
					if (!abortController.signal.aborted) {
						onErrorsChanged(machineId, data.error.code > 0 ? [createError(machineId, data.error)] : []);
					}
				} catch (error) {
					console.error(`Failed to retrieve errors and warnings for ${machineType} machine: ${machineId}`);
				}
			}

			if (!abortController.signal.aborted) {
				setStaleData(false);
			}
		}

		const abortController = new AbortController();
		getErrorsAndWarnings(abortController);
		return () => abortController.abort();
	}, [machineIds, staleData]);
}
