import Flex from "@/components/flex";
import { JobQueueContext } from "../job-queue-provider";
import MachineGroupStatusIcon from "@/views/production/machine-group-control/machine-group-status-icon";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import Card from "@/components/card";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import { BodyLarge, BodyRegular } from "@/components/typography";
import InProductionOrder from "./in-production-order";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 144px;
		flex-shrink: 0;
	}
`;

const GreyBodyLarge = styled(BodyLarge)`
	color: ${({ theme }) => theme.colors.darkGreyText};
`;

function InProductionOrdersCard({ machineGroup }) {
	const { selectedBatches, batchesLoading } = useContext(JobQueueContext);

	const productionEnabled = machineGroup.productionEnabled;

	const getMachineStatusMessage = () => {
		switch (machineGroup.status.toLowerCase()) {
			case "initializing":
				return "Machine group is initializing";
			case "service":
			case "machineservice":
				return "Machine is in service mode";
			case "error":
				return "Machine group is in error mode";
			case "paused":
				return "Machines are paused, ensure all machines are online and ready";
			case "offline":
				return "Machine group is not currently online, verify the machines are online";
			case "online":
				return "Machine group is online but paused, press play to start receiving jobs";
			default:
				return `State ${machineGroup.status.toLowerCase()} not handled`;
		}
	};

	return (
		<StyledCard>
			<Flex alignCenter justifyCenter gap={16} grow>
				{!productionEnabled && (
					<>
						<MachineGroupStatusIcon machineGroupStatus={machineGroup.status} />
						<GreyBodyLarge>
							<FormattedMessage id={getMachineStatusMessage()} />
						</GreyBodyLarge>
					</>
				)}
				{productionEnabled && batchesLoading && (
					<Flex column gap={8} style={{ width: "100%" }}>
						<BodyRegular>
							<FormattedMessage
								id="Producing on {machineGroupName}"
								defaultMessage="Producing on {machineGroupName}"
								values={{ machineGroupName: machineGroup.alias }}
							/>
						</BodyRegular>
						<Loader active inline="centered" data-testid="loader" />
					</Flex>
				)}
				{productionEnabled && !batchesLoading && selectedBatches.length === 0 && (
					<>
						<MachineGroupStatusIcon machineGroupStatus={machineGroup.status} />
						<GreyBodyLarge>
							<FormattedMessage id="Ready to receive jobs" />
						</GreyBodyLarge>
					</>
				)}
				{productionEnabled && !batchesLoading && selectedBatches.length > 0 && (
					<Flex column gap={16} style={{ width: "100%" }}>
						<BodyRegular>
							<FormattedMessage
								id="Producing on {machineGroupName} ({count})"
								defaultMessage="Producing on {machineGroupName} ({count})"
								values={{ machineGroupName: machineGroup.alias, count: selectedBatches.length }}
							/>
						</BodyRegular>
						{selectedBatches.map((batch) => (
							<InProductionOrder selectedBatch={batch} />
						))}
					</Flex>
				)}
			</Flex>
		</StyledCard>
	);
}

export default InProductionOrdersCard;
