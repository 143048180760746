import { Button, Checkbox, Dropdown, Form, FormField, FormGroup, Input, Tab, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import PrintHelpers from "./x5wPrint-service-helpers";
import { v4 } from "uuid";
import successIcon from "../../../../assets/images/package-creation/success-small.svg";
import Loading from "@/components/loading";

const useStyles = createUseStyles((theme) => ({
	radio: {
		margin: "16px !important",
		marginBottom: "-6px !important",
	},
	notificationSuccess: {
		backgroundColor: "#fcfff5",
		border: "solid 0.063rem #7ed321",
		borderRadius: "0.375rem",
		color: "#7ed321",
		"& a": {
			color: "#7ed321",
			display: "flex",
			alignItems: "center",
			fontSize: "0.75rem",
		},
	},
	notificationContainer: {
		display: "flex",
		flex: 1,
		justifyContent: "space-between",
		alignItems: "center",
		fontFamily: "Stolzl-Book !important",
		fontSize: "1rem !important",
		fontWeight: "normal !important",
		fontStyle: "normal !important",
		width: "28.75rem",
		padding: "0.75rem 1rem 0.75rem 1rem",
		"& img": {
			marginLeft: "0.5rem",
		},
		"& div": {
			display: "flex",
		},
	},
	loading: {
		width: "fit-content",
		paddingLeft: "2em",
		height: "100px",
	},
}));

export const X5wPrintService = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const printConfigApi = useAxios(
		`/PrintImageApi/api/v1/PrintConfig?iotEdgeDeviceId=${props.selectedMachine.iotEdgeDeviceId}`,
		token,
	);
	const technicalJobApi = useAxios(
		`/PrintImageApi/api/v1/PrintImage/technicalJobs/${props.selectedMachine.iotEdgeDeviceId}`,
		token,
	);
	const printImageApi = useAxios("/PrintImageApi/api/v1/PrintImage", token);
	const packagingSolutionsApi = useAxios("/importapi/api/v1/packagingsolutions", token);
	const machineGroupsApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);
	const selectedMachine = props.selectedMachine;
	const [design, setDesign] = useState(null);
	const [imageSource, setImageSource] = useState(null);
	const [colorWise, setColorwise] = useState(null);
	const [colorProfile, setColorProfile] = useState(null);
	const [colorMode, setColorMode] = useState(null);
	const [colorant, setColorant] = useState(null);
	const [packNetSource, setPackNetSource] = useState(null);
	const [sftpSource, setSFTPSource] = useState(null);
	const [fierySource, setFierySource] = useState(null);
	const [cartonLength, setCartonLength] = useState(null);
	const [cartonWidth, setCartonWidth] = useState(null);
	const [cartonHeight, setCartonHeight] = useState(null);
	//const [selectedPrintImage, setSelectedPrintImage] = useState(null);
	const [printImages, setPrintImages] = useState(null);
	const [selectPdfOptions, setSelectPdfOptions] = useState([]);
	const [selectColorProfileOptions, setSelectColorProfileOptions] = React.useState([]);
	const [selectColorModeOptions, setSelectColorModeOptions] = React.useState([]);
	const [selectColorantOptions, setSelectColorantOptions] = React.useState([]);
	const [technicalJobsOptions, setTechnicalJobsOptions] = React.useState([]);
	const [showNotification, setShowNotification] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isSmartScaling, setIsSmartScaling] = useState(false);
	const [fssFilepath, setFssFilepath] = useState("");
	const [dotFilepath, setDotFilepath] = useState("");
	const [shippingFilepath, setShippingFilepath] = useState("");
	const [barcodeFilepath, setBarcodeFilepath] = useState("");
	const [boxId, setBoxId] = useState(0);

	useEffect(() => {
		// call print images and set them
		setDesign(selectedMachine.settings.designId);
		PrintHelpers.GetPrintConfig(
			printConfigApi,
			setSelectColorProfileOptions,
			setSelectColorModeOptions,
			setSelectColorantOptions,
			(yup) => {},
			(nope) => {},
		);
		PrintHelpers.GetTechnicalJobs(
			technicalJobApi,
			setTechnicalJobsOptions,
			(yup) => {},
			(nope) => {},
		);
	}, []);

	useEffect(() => {
		setSelectPdfOptions([]);
		setPrintImages([]);
		PrintHelpers.GetPrintImages(
			printImageApi,
			parseInt(cartonLength),
			parseInt(cartonWidth),
			parseInt(cartonHeight),
			(filteredPrintImages) => {
				let newImageArray = [];
				for (let i = 0; i < filteredPrintImages.length; i++) {
					newImageArray.push({
						key: i,
						value: filteredPrintImages[i].id,
						text: filteredPrintImages[i].alias,
					});
					if (i === filteredPrintImages.length - 1) {
						setSelectPdfOptions(newImageArray);
						setPrintImages(filteredPrintImages);
					}
				}
			},
			(errorCallBack) => {
				console.info(errorCallBack);
			},
		);
	}, [cartonHeight, cartonLength, cartonWidth]);

	const createAndSubmitTestJob = async () => {
		setLoading(true);
		// Create object needed to send the job from all the values provided
		// Including machine sent in
		// Need to create API (PackageCreation page) method to get design, etc.
		let carton = {
			additionalDimensions: {},
			attributes: {},
			contents: [],
			cost: 0,
			documents: [],
			errors: [],
			fillers: [],
			id: "",
			labels: [],
		};
		carton.media = {
			Dimensions: {
				Length: cartonLength,
				Width: cartonWidth,
				Height: cartonHeight,
			},
			Style: design.toString(),
			Attributes: {
				X1: "2.5",
				name: "Service Test Carton",
				quantity: "1",
			},
		};
		carton.prints = [
			{
				placement: {
					X: 0.0,
					Y: 0.0,
				},
				media: {
					dimensions: {
						Length: 0,
						Width: 0,
						Height: 0,
					},
					thickness: 0.0,
					style: null,
					brand: null,
					cost: 0.0,
					attributes: {},
				},
				rotation: 0.0,
				layer: 1,
				cost: 0.0,
				value:
					imageSource === "Packnet"
						? packNetSource
						: imageSource === "SFTP"
							? isSmartScaling
								? fssFilepath
								: sftpSource
							: "technicaljob:" + fierySource,
				requiredTimeToPack: "0001-01-01T00:00:00Z",
				attributes: {
					colorWiseOff: colorWise,
					ColorMode: colorMode,
					ColorProfile: colorProfile,
					Colorant: colorant,
					"@@NoValidatePrintImage": imageSource === "Packnet" ? "False" : "True",
					smartScaling: isSmartScaling.toString(),
					lpnImagePath: barcodeFilepath !== "" ? barcodeFilepath : "",
					dotPath: dotFilepath !== "" ? dotFilepath : "",
					shippingPath: shippingFilepath !== "" ? shippingFilepath : "",
					boxId: boxId.toString(),
				},
			},
		];
		let solutionToSend = {
			userName: null,
			tenantId: selectedMachine.tenantId,
			batchId: null,
			alias: "Service Job-" + imageSource,
			licensePlateNumber: "",
			cartons: [carton],
			classification: null,
			contentWeight: 0,
			createByDate: "0001-01-01T00:00:00+00:00",
			documents: [],
			errors: [],
			estimatedTimeToPack: "00:00:00",
			estimatedTotalCost: 0,
			fillers: [],
			groupIdDate: "0001-01-01T00:00:00+00:00",
			id: v4(),
			importDate: "0001-01-01T00:00:00+00:00",
			internalUniqueId: "00000000-0000-0000-0000-000000000000",
			isExpanded: false,
			priority: 0,
			releasedForProductionDate: "0001-01-01T00:00:00+00:00",
			options: { prioritizeProductionBy: "MaterialUsage", maxFailedProductionRetries: 2147483647 },
		};
		const setCallback = (yup) => {
			console.info("yup: ", yup);
		};
		const errorCallBack = (nope) => {
			console.info("nope: ", nope);
		};
		await PrintHelpers.SubmitJob(
			packagingSolutionsApi,
			machineGroupsApi,
			solutionToSend,
			selectedMachine,
			setCallback,
			errorCallBack,
		)
			.then((data) => {
				//show submitted
				setShowNotification(true);
				setLoading(false);
			})
			.catch((error) => {});
	};

	const imageSourceOptions = [
		{
			key: "1",
			value: "Packnet",
			text: "Packnet",
		},
		{
			key: "2",
			value: "SFTP",
			text: "External SFTP server",
		},
		{
			key: "3",
			value: "Fiery",
			text: "Image is located on Fiery server",
		},
	];

	const selectColorWiseOptions = [
		{
			key: "1",
			value: true,
			text: "On",
		},
		{
			key: "2",
			value: false,
			text: "Off",
		},
	];

	const panes = [
		{
			menuItem: intl.formatMessage({ id: "Send Test Print" }),
			render: () => (
				<Tab.Pane>
					{showNotification && (
						<div className={`${classes.notificationContainer} ${classes.notificationSuccess}`}>
							{`${intl.formatMessage({ id: "Produce" })} Job Sent ...`}
							<div>
								<a href="../search">
									<u>{intl.formatMessage({ id: "SEE QUEUE" })}</u>
								</a>
								<img src={successIcon} />
							</div>
						</div>
					)}
					<Form basic="very" celled>
						<h2 data-testid="designId">Carton Design: {design}</h2>
						<h3>Enter Carton Dimensions</h3>
						<FormGroup>
							<FormField
								onChange={(_, v) => {
									setCartonLength(v.value);
								}}
								data-testid="cartonLength"
								control={Input}
								label="Length"
								placeholder="Enter Carton Length"
							/>
							<FormField
								onChange={(_, v) => {
									setCartonWidth(v.value);
								}}
								data-testid="cartonWidth"
								control={Input}
								label="Width"
								placeholder="Enter Carton Width"
							/>
							<FormField
								onChange={(_, v) => {
									setCartonHeight(v.value);
								}}
								data-testid="cartonHeight"
								control={Input}
								label="Height"
								placeholder="Enter Carton Height"
							/>
						</FormGroup>
						<FormGroup>
							<label>Select Image Source</label>
							<FormField width={4}>
								<Dropdown
									data-testid="ImageSource"
									onChange={(_, v) => {
										PrintHelpers.GetPrintImages(
											printImageApi,
											parseInt(cartonLength),
											parseInt(cartonWidth),
											parseInt(cartonHeight),
											(filteredPrintImages) => {
												let newImageArray = [];
												for (let i = 0; i < filteredPrintImages.length; i++) {
													newImageArray.push({
														key: i,
														value: filteredPrintImages[i].id,
														text: filteredPrintImages[i].alias,
													});
													if (i === filteredPrintImages.length - 1) {
														setSelectPdfOptions(newImageArray);
														setPrintImages(filteredPrintImages);
													}
												}
											},
											(errorCallBack) => {
												console.info(errorCallBack);
											},
										);
										setImageSource(v.value);
									}}
									disabled={cartonLength && cartonWidth && cartonHeight ? false : true}
									placeholder="Select Image Source"
									fluid
									selection
									options={imageSourceOptions}
								/>
							</FormField>
						</FormGroup>
						<FormGroup>
							{imageSource === "Packnet" && (
								<div>
									<label>Select PDF from list</label>
									<FormField
										data-testid="packnet"
										onChange={(_, e) => {
											for (let image of printImages) {
												if (image.id === e.value) {
													setPackNetSource(image.alias);
												}
											}
										}}
										control={Dropdown}
										placeholder={selectPdfOptions.length > 0 ? "Select PDF" : "No PDF's Found"}
										disabled={selectPdfOptions.length > 0 ? false : true}
										fluid
										selection
										options={selectPdfOptions}
									/>
								</div>
							)}
						</FormGroup>
						<FormGroup>
							{imageSource === "SFTP" && (
								<>
									<Checkbox
										label="Smart Scaling Job"
										onChange={(e, data) => setIsSmartScaling(data.checked)}
										checked={isSmartScaling}
										toggle
									/>
								</>
							)}
						</FormGroup>
						{imageSource === "SFTP" && (
							<>
								{!isSmartScaling ? (
									<div>
										<label>Enter SFTP file path</label>
										<FormField
											data-testid="sftp"
											onChange={(_, e) => setSFTPSource(e.value)}
											width={4}
											control={Input}
											label=""
											placeholder="Enter Image Filepath"
										/>
									</div>
								) : (
									<>
										<FormGroup>
											<Input
												label=".fss"
												labelPosition="right"
												placeholder=".fss Filepath"
												value={fssFilepath}
												onChange={(e, data) => setFssFilepath(data.value)}
											/>
										</FormGroup>
										<FormGroup>
											<Input
												placeholder="Shipping Label Filepath"
												label=".png/.jpeg/.tiff"
												labelPosition="right"
												value={shippingFilepath}
												onChange={(e, data) => setShippingFilepath(data.value)}
											/>
										</FormGroup>
										<FormGroup>
											<Input
												labelPosition="right"
												placeholder="DOT Label Filepath"
												label=".png/.jpeg/.tiff"
												value={dotFilepath}
												onChange={(e, data) => setDotFilepath(data.value)}
											/>
										</FormGroup>
										<FormGroup>
											<Input
												labelPosition="right"
												placeholder="LPN Label Filepath"
												label=".png/.jpeg/.tiff"
												value={barcodeFilepath}
												onChange={(e, data) => setBarcodeFilepath(data.value)}
											/>
										</FormGroup>
										<FormGroup>
											<Input
												placeholder="Box Id"
												label="Box Id"
												labelPosition="right"
												value={boxId}
												onChange={(e, data) => setBoxId(parseInt(data.value))}
											/>
										</FormGroup>
									</>
								)}
							</>
						)}
						{imageSource === "Fiery" && (
							<div>
								<label>Select Fiery job</label>
								<FormField
									data-testid="fiery"
									onChange={(_, e) => setFierySource(e.value)}
									control={Dropdown}
									label=""
									fluid
									selection
									width={5}
									placeholder="Select"
									options={technicalJobsOptions}
								/>
							</div>
						)}
						<br />
						<label>RIP Settings</label>
						<FormGroup widths={"equal"}>
							<FormField
								onChange={(_, e) => setColorwise(e.value)}
								control={Dropdown}
								label="ColorWise"
								fluid
								selection
								placeholder="Select"
								options={selectColorWiseOptions}
							/>
							<FormField
								onChange={(_, e) => setColorProfile(e.value)}
								control={Dropdown}
								label="Color Profile"
								fluid
								selection
								placeholder="Select"
								options={selectColorProfileOptions}
							/>
							<FormField
								data-testid="ColorMode"
								onChange={(_, e) => setColorMode(e.value)}
								control={Dropdown}
								label="Color Mode"
								fluid
								selection
								placeholder="Select"
								options={selectColorModeOptions}
							/>
							{colorMode === "cmykplus" && (
								<FormField
									data-testid="Colorant"
									onChange={(_, e) => setColorant(e.value)}
									control={Dropdown}
									label="Colorant"
									fluid
									selection
									placeholder="Select"
									options={selectColorantOptions}
								/>
							)}
						</FormGroup>
						<Button
							primary
							onClick={(e) => {
								e.stopPropagation();
								createAndSubmitTestJob();
							}}
						>
							<FormattedMessage id="Produce Job" />
						</Button>
						{loading && <Loading className={classes.loading} messageId="Sending Job" />}
					</Form>
				</Tab.Pane>
			),
		},
		{
			menuItem: intl.formatMessage({ id: "Update Module Twin" }),
			render: () => (
				<Tab.Pane>
					<Table basic="very" celled>
						<Table.Body>
							{/*

							*/}
							<Table.Row>Second Pane</Table.Row>
						</Table.Body>
					</Table>
				</Tab.Pane>
			),
		},
	];

	return (
		<Tab menu={{ fluid: true, vertical: true, tabular: true }} grid={{ tabWidth: 2, paneWidth: 14 }} panes={panes} />
	);
};

export default X5wPrintService;
