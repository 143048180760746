import { useContext } from "react";
import DataTable from "react-data-table-component";
import Loading from "@/components/loading";
import { FormattedMessage, useIntl } from "react-intl";
import { BodyLarge, BodyRegular } from "@/components/typography";
import AvailableJobsActionMenu from "@/views/production/orders/available-jobs-action-menu/available-jobs-action-menu";
import { useCanManageJobs } from "@/components/user-context";
import Flex from "@/components/flex";
import styled from "styled-components";
import { getJobContentsString } from "@/views/production/orders/utils/utils";
import { OrdersContext } from "@/views/production/orders/orders";

const GreyBodyLarge = styled(BodyLarge)`
	color: ${({ theme }) => theme.colors.darkGreyText};
`;

function AvailableJobsTable({ batches, pageSize, setPageNumber, isLoading, totalResults }) {
	const intl = useIntl();
	const userCanRemove = useCanManageJobs();
	const { scanToTriggerConfigured } = useContext(OrdersContext);

	const columns = [
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Name" />
				</BodyRegular>
			),
			selector: (row) => <BodyRegular>{row.alias}</BodyRegular>,
		},
		{
			omit: !scanToTriggerConfigured,
			name: (
				<BodyRegular>
					<FormattedMessage id="Trigger Value" />
				</BodyRegular>
			),
			selector: (row) => <BodyRegular>{row.licensePlateNumber}</BodyRegular>,
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Contents" />
				</BodyRegular>
			),
			selector: (row) => (
				<BodyRegular>
					{getJobContentsString({
						cartonCountPerJob: row.cartonCountPerJob,
						labelCountPerJob: row.labelCountPerJob,
						cartonDimension: row.cartonDimension,
						intl,
					})}
				</BodyRegular>
			),
		},
		{
			name: (
				<BodyRegular>
					<FormattedMessage id="Job Quantity" />
				</BodyRegular>
			),
			selector: (row) => <BodyRegular>{row.totalJobs}</BodyRegular>,
		},
		{
			selector: (row) =>
				row.status === "ProducingNext" ? (
					<BodyRegular>
						<FormattedMessage id="Producing Next" />
					</BodyRegular>
				) : row.status === "Triggered" ? (
					<BodyRegular>
						<FormattedMessage id="Waiting To Produce" />
					</BodyRegular>
				) : null,
			maxWidth: "200px",
			right: true,
		},
		{
			omit: !userCanRemove,
			right: true,
			cell: (row) => <AvailableJobsActionMenu batchId={row.batchId} />,
			maxWidth: "50px",
		},
	];

	if (batches.length === 0) {
		return (
			<Flex alignCenter justifyCenter grow>
				<GreyBodyLarge>
					<FormattedMessage id="There are no records to display" />
				</GreyBodyLarge>
			</Flex>
		);
	}

	return (
		<DataTable
			keyField="batchId"
			noHeader
			striped
			pagination
			paginationServer
			onChangePage={(pageNumber) => setPageNumber(pageNumber)}
			progressPending={isLoading}
			progressComponent={<Loading />}
			paginationComponentOptions={{ noRowsPerPage: true }}
			paginationTotalRows={totalResults}
			paginationPerPage={pageSize}
			columns={columns}
			data={batches}
			customStyles={{
				responsiveWrapper: { style: { overflow: "visible", flexGrow: 1 } },
			}}
		/>
	);
}

export default AvailableJobsTable;
