import "unfonts.css";
import "./index.css";
import "semantic-ui-less/semantic.less";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { enableMapSet } from "immer";

enableMapSet();

createRoot(document.getElementById("root")).render(
	<StrictMode>
		<BrowserRouter basename="PackNet">
			<App />
		</BrowserRouter>
	</StrictMode>,
);
