import Card from "@/components/card";
import Flex from "@/components/flex";
import { Progress } from "semantic-ui-react";
import { BodyBook, Header1, SmallRegular } from "@/components/typography";
import { FormattedMessage, useIntl } from "react-intl";
import InProgressJobsActionMenu from "@/views/production/orders/in-production-orders-card/in-progress-jobs-action-menu";

import styled from "styled-components";
import { getJobContentsString } from "@/views/production/orders/utils/utils";
import { useContext } from "react";
import { OrdersContext } from "../orders";

const StyledCard = styled(Card)`
	&&& {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-shrink: 0;
		padding: 0;
		background-color: ${(props) => props.theme.colors.lightBackground};
	}
`;

const StyledHeader1 = styled(Header1)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const GreySmallRegular = styled(SmallRegular)`
	color: ${(props) => props.theme.colors.darkGreyText};
`;

function Column({ messageId, value, ...props }) {
	return (
		<Flex column gap={8} {...props}>
			<GreySmallRegular>
				<FormattedMessage id={messageId} />
			</GreySmallRegular>
			<BodyBook>{value}</BodyBook>
		</Flex>
	);
}

export const waitingToTriggerStatus = "Waiting to trigger";

export function getOrderStatusString(selectedBatch, scanToTriggerSingleConfigured) {
	let statusString;

	if (
		scanToTriggerSingleConfigured &&
		selectedBatch.status === "Producing" &&
		selectedBatch.selectedCount === selectedBatch.producedCount &&
		selectedBatch.selectedCount !== selectedBatch.totalCount
	) {
		statusString = waitingToTriggerStatus;
	} else {
		statusString = selectedBatch.status;
	}

	return statusString;
}

export function getStatusBarColor(orderStatus) {
	return orderStatus === "Paused" || orderStatus === "Pausing" || orderStatus === waitingToTriggerStatus
		? "grey"
		: "green";
}

function InProductionOrder({ selectedBatch }) {
	const intl = useIntl();
	const { cartonCountPerJob, labelCountPerJob, cartonDimension } = selectedBatch;
	const hideContentColumn = cartonCountPerJob === undefined && labelCountPerJob === undefined;

	const { scanToTriggerConfigured, scanToTriggerSingleConfigured } = useContext(OrdersContext);

	const orderStatus = getOrderStatusString(selectedBatch, scanToTriggerSingleConfigured);
	return (
		<StyledCard>
			<Flex style={{ margin: 16 }} justifyBetween alignCenter>
				<Flex column style={{ width: "30%" }}>
					<StyledHeader1>{selectedBatch.alias}</StyledHeader1>
				</Flex>
				{scanToTriggerConfigured && selectedBatch.licensePlateNumber && (
					<Column messageId="Trigger Value" value={selectedBatch.licensePlateNumber} />
				)}
				{!hideContentColumn && (
					<Column
						messageId="Contents"
						value={getJobContentsString({ cartonCountPerJob, labelCountPerJob, cartonDimension, intl })}
					/>
				)}
				<Column messageId="Quantity" value={`${selectedBatch.producedCount}/${selectedBatch.totalCount}`} />
				<Flex gap={24}>
					<Column
						messageId="Status"
						value={intl.formatMessage({ id: orderStatus })}
						style={{ minWidth: 150, alignItems: "flex-end" }}
					/>
					<Flex alignCenter justifyCenter>
						<InProgressJobsActionMenu batchId={selectedBatch.batchId} />
					</Flex>
				</Flex>
			</Flex>
			<Progress
				value={selectedBatch.producedCount}
				total={selectedBatch.totalCount}
				active
				attached="bottom"
				size="small"
				color={getStatusBarColor(orderStatus)}
				style={{ margin: 0, height: 8 }}
			/>
		</StyledCard>
	);
}

export default InProductionOrder;
