export function getSplits(value) {
	var strNo = value.toLocaleString("en-US", {
		maximumFractionDigits: 0,
	});
	return strNo.split(",");
}

export function getNextDivisibleByFour(value) {
	const splits = getSplits(value);
	let v = parseInt(splits[0]);
	if (v % 4 === 0) {
		if (v === parseInt(splits[0]) && parseInt(value.toString().slice(1)) > 0) {
			v += 4;
		}
	} else {
		v = v + 4 - (v % 4);
	}
	var commaCount = splits.length - 1;
	let result = v * 10 ** (commaCount * 3);
	
	if (result === 0) {
		return 4;
	} else { 
		return result;
	}
}

export function getSwimLaneMaxiumum(value) {
	const upper = value / 0.8;
	return getNextDivisibleByFour(upper);
}

export function getNumericAbbreviation(value, addTrailingValue) {
	if (value === undefined) {
		return 0;
	}
	var strResult = "";
	const splits = getSplits(value);
	const commaCount = splits.length - 1;
	switch (commaCount) {
		case 1:
			strResult = "K";
			break;
		case 2:
			strResult = "M";
			break;
		case 3:
			strResult = "B";
			break;
		case 4:
			strResult = "T";
			break;
		case 5:
			strResult = "Q";
			break;
		default:
			strResult = "";
			break;
	}
	if (addTrailingValue && commaCount > 0) {
		return parseFloat(splits[0] + "." + splits[1]).toString() + strResult;
	}
	return splits[0] + strResult;
}

export function getPaginationResult(site, pageSize, page) {
	if (!site || !site.items) {
		return {
			items: [],
			heading: "",
			hasNext: false,
			hasPrevious: false,
			totalPages: 0,
		};
	}
	const items = site.items.slice((page - 1) * pageSize, page * pageSize);
	const result = {
		items: items,
		heading: site.heading,
		hasNext: page * pageSize < site.items.length,
		hasPrevious: page > 1,
		totalPages: Math.ceil(site.items.length / pageSize),
	};
	return result;
}

export function zFoldNameLine(limit, alias, width, brand, flute, brandText, fluteText) {
	if (limit === 1000) {
		const brandSection = brand ? `${brandText} ${brand} - ` : "";
		const fluteSection = flute ? `${fluteText} ${flute} ` : "";
		return `${width}" ${brandSection}${fluteSection}(${alias})`;
	} else {
		return alias;
	}
}
